.bg-yellow {
    background: #f6c445;
  }
  
  .thank-you {
    clip-path: ellipse(62% 63% at 46% 68%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    /* position: relative;
    top: 80px; */
  }
  
  .thank-you .ThankYouAnimation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: auto;
    margin-top: 10%;
  }
  
  @media screen and (max-width: 500px) {
  .thank {
      clip-path: ellipse(81% 56% at 45% 60%);
  }
    .thank-you .ThankYouAnimation {
      margin-top: 30%;
      width: 200px;
    }
  }
  
  .thank-you h5 {
    margin-top: 20px;
  }
  
  .thank-you .btn-submit,
  .thank-you .btn-submit:hover {
    background: #f6c445;
    color: black !important;
  }
  