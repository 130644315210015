:root {
    --grey-color: #afafaf;
    --off-white: #e2e2e2;
    --light-blue:#9ac1f0;
    --dark-green: #A0E548;
    --pastel-green: #72FA93;
}

.posts {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 1.6em;
    font-size: 18px;
    box-sizing: border-box;
}

.post {
    margin: 32px auto;
    margin-bottom: 60px;
}

.flex-gap-30{
    gap: 30%;
}

.post-header {
    margin: 0 0 15px 0;
}

.title {
    font-weight: 400;
    font-size: 1.6rem;
    margin: 0;
}

.title-link{
    color: var(--off-white);
    text-decoration: none;
    word-break: break-word;
    transition: color 0.2s ease;
}

.title a:hover {
    color: var(--light-blue);
}

.meta {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #C5C5C5;
    margin: 18px 0 80px;
    padding-top: 18px;
}

.avatar {
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
    display: inline;
    border-radius: 50%;
    margin-right: 12px;
}

.author-tiny .name {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 18px;
    box-sizing: border-box;
    color: var(--off-white);
    cursor: pointer;
    text-decoration: none;
    word-break: break-word;
    transition: color 0.2s ease;
    line-height: 1.6;
    vertical-align: middle;
}

.author-tiny .name:hover {
    color: var(--pastel-green)
}

.design {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 1.6em;
    font-size: 18px;
    box-sizing: border-box;
    vertical-align: middle;
    margin-left: 12px;
    color: #888;
}

.date {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 1.6em;
    box-sizing: border-box;
    font-size: 0.875em;
    margin: 0;
    color: #888;
}

.blogpost-content p {
    color: var(--grey-color);
}

.blogpost-content img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}