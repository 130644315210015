:root{
    --grey-color: #afafaf;
    --link-color: #007bff;
    --base-font-size: 13px;
}

.terms-and-conditions {
    text-align: left;
}

.privacy-policy-content,
.terms-and-conditions-content {
    font-size: var(--base-font-size);
    line-height: 1.5;
    margin-top: 20px;
    color: #fff;
}


.privacy-policy-content p,
.terms-and-conditions-content p {
    margin-bottom: 10px;
    color: var(--grey-color);
}

.privacy-policy-content h2,
.terms-and-conditions-content h2 {
    margin: 20px 0 20px 0;
    font-size: calc(var(--base-font-size) + 18px);
}

.privacy-policy-content h3,
.terms-and-conditions-content h3 {
    margin-bottom: 10px;
    font-size: calc(var(--base-font-size) + 12px);
}

.privacy-policy-content h4,
.terms-and-conditions-content h4 {
    margin-bottom: 15px;
    font-size: calc(var(--base-font-size) + 6px);
}

.privacy-policy-content ul,
.terms-and-conditions-content ul {
    margin-bottom: 10px;
    margin-left: 20px;
    color: var(--grey-color);
}

.privacy-policy-content a,
.terms-and-conditions-content a {
    color: var(--link-color);
    text-decoration: none;
}

.privacy-policy-content a:hover,
.terms-and-conditions-content a:hover {
    text-decoration: underline;
}


@media (max-width: 768px) {

    .privacy-policy-content,
    .terms-and-conditions-content {
        font-size: 12px;
    }

    .privacy-policy-content h2,
    .terms-and-conditions-content h2 {
        font-size: 20px;
    }

    .privacy-policy-content h3,
    .terms-and-conditions-content h3 {
        font-size: 18px;
    }

    .privacy-policy-content ul,
    .terms-and-conditions-content ul {
        margin-left: 15px;
    }
}