/* Login page */
.login,
.signup {
  min-height: 80vh;
}

.signup .container,
.login .container {
  background: var(--yellow);
  color: black;
  border-radius: 20px;
  max-width: 500px;
  padding: 50px;
  min-width: 350px;

  /* max-width: 90%; */
}
.login .login__form,
.signup .signup__form {
  width: 100%;
  max-width: 350px;
}

.signup .signup__form button:hover,
.login .login__form button:hover {
  background-color: var(--pastel-green);
}


.login .login__form form input,
.signup .signup__form form input {
  padding: 10px 18px 0;
  height: 60px;
  width: 320px;
  border: 1px solid black;
}
.login .login__form form .error,
.signup .signup__form form .error {
  color: red;
  margin: 0px 0px 8px 0px;
  border-radius: .375rem;
  padding: 10px 10px 10px 0;
  width: 320px;
}
.login .login__form form .form-floating > label,
.signup .signup__form form .form-floating > label {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0;
  padding-left: 20px;
  font-weight: 500;
  line-height: 1.4;
  font-size: 14;
  transform-origin: -10px -60px;
}
.login .login__form .form-control,
.signup .signup__form .form-control {
  font-size: 14px;
}
.login .login__form .form-control:focus,
.signup .signup__form .form-control:focus,
.signup .signup__form form button:hover,
.signup .signup__form form button:active {
  box-shadow: none;
}

.login .login__form form .form-floating a,
.signup .signup__form form .form-floating a {
  position: absolute;
  top: 16px;
  right: 10px;
  font-size: 20px;
}

.login .login__form form button,
.signup .signup__form form button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-blue);
  margin-bottom: 16px;
  height: 60px;
  font-size: 20px;
  font-weight: 700;
  border-radius: .375rem;
  border: 1px solid black;
  /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%); */
}

.login .login__form form button:hover,
.login .login__form form button:active,
.signup .signup__form form button:hover,
.signup .signup__form form button:active {
  background-color: var(--pastel-green);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.login .login__form .login__signup a,
.signup .signup__form .signup__login a {
  text-decoration: underline;
  color: var(--blue);
  font-weight: 700;
}

/* Sinup page */

.signup .signup__form .signup__terms-and-policy__underline {
  margin: 16px 0;
  border-top: 1px solid black;
}
.signup .signup__form .signup__terms-and-policy {
  width: 320px;
}
.signup .signup__form .signup__terms-and-policy p a {
  color: var(--blue);
}

.login .login__form .google,
.signup .signup__form .google {
  display: flex;
  align-items: center;
  padding: 0 18px;
  height: 60px;
  width: 320px;
  border-radius: .375rem;
  border: 1px solid black;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 18px;
  background-color: white;
}

.login .login__form button i,
  .signup .signup__form button i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-right: 8px;
}
