.App-header .navbar .user,
.App-header .navbar .user img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 40px;
  font-size: 35px;
  cursor: pointer;
  border-radius: 50%;
}

.App-header .navbar .user .user-details-wrapper {
  position: absolute;
  top: 0px;
  right: 0;
}

.App-header .navbar .user .user-details {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  background-color: var(--yellow);
  padding: 10px;
  border-radius: 10px;
  border: solid 2px var(--dark-bg);
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 1000;
}

.App-header .navbar .user .user-details.visible {
  display: flex;
}

.App-header .navbar .user .user-details a {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header .navbar .user .user-details p {
  font-size: 16px;
}

.App-header .navbar a:hover {
  cursor: pointer;
}