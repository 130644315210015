.bg-yellow {
  background: var(--yellow);
}

.not-found {
  clip-path: ellipse(62% 63% at 46% 68%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  /* position: relative;
  top: 80px; */
}

.not-found .NotFound404Animation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: auto;
  margin-top: 10%;
}

@media screen and (max-width: 500px) {
.not-found {
    clip-path: ellipse(81% 56% at 45% 60%);
}
  .not-found .NotFound404Animation {
    margin-top: 30%;
    width: 200px;
  }
}

.not-found h5 {
  margin-top: 20px;
}

.not-found .btn-submit,
.not-found .btn-submit:hover {
  background: var(--yellow);
  color: black !important;
}
