@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  margin: 0;
}


body {
  font-family: 'Poppins', 'Franklin Gothic Medium', Helvetica, sans-serif;
  color: white;
  font-size: 90%;
  background: black;
}

:root {
  --blue-red-gradient: 80deg, rgba(54, 0, 0, 1) 0%, rgba(31, 0, 57, 1) 49%, rgba(0, 50, 109, 1) 100%;
  --black-red-gradient: 80deg, rgba(0, 0, 0, 0.225) 49%, rgb(0, 0, 0, 0.225) 200%;
  --blue: #00326D;
  --baby-powder: #FDFDFBff;
  --dark-bg: black;
  --pastel-blue: #b9f5fa;
  --pastel-pink: #F4C3C3;


  /* HOME */
  --light-blue: #9ac1f0;
  --pastel-green: #72FA93;
  --dark-green: #A0E548;
  --yellow: #F6C445;

  /* COLOR PALLETE: 00326D,9ac1f0,510026,420079,000 */
}

.home-card {
  margin-top: 10px;
  width: 55vmin;
  height: 254px;
  border-radius: 4px;
  background: var(--bs-black);
  display: flex;
  gap: 5px;
  padding: .4em;
}

.home-card p {
  font-size: smaller;
  height: 100%;
  flex: 1;
  overflow: hidden;
  border-radius: 2px;
  transition: all .5s;
  background: var(--bs-black);
  border: 1px solid var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.home-card a {
  cursor: pointer;
}

.home-card p:hover {
  flex: 4;
}



.home-card p span {
  min-width: 14em;
  padding: .7em;
  text-align: center;
  transform: rotate(-90deg);
  transition: all .5s;
  text-transform: uppercase;
  color: var(--dark-green);
  letter-spacing: .1em;
  line-height:22px;

}

.home-card p:hover span {
  transform: rotate(0);
}

/* 
.home-card p:active span {
  transform: rotate(0);
  transition: all 0.2s;
  transition-delay: 300ms;
}
 */

.text-pastel-green-hover:hover {
  color: var(--pastel-green);
}

.text-yellow {
  color: var(--yellow);
}

.height-100vh-fixed {
  height: 100vh;
  display: flex;
  align-items: center;
}

.home-logo {
  scale: calc(2);
}

.text-pastel-green {
  color: var(--pastel-green);
}

.text-dark-green {
  color: var(--dark-green);
}

.text-light-blue {
  color: var(--light-blue);
}

/* dark blue:  rgb(0, 44, 44) */

.App-header {
  /* background-color: #282c34; */
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  font-family: Georgia, serif;
  font-size: smaller;
  color: white;
  background-color: var(--yellow);
}

.navbar {
  top: 0;
  position: sticky;
  padding-right: 2%;
  z-index: 1;
}

.font-size-small {
  font-size: smaller
}

.font-size-larger {
  font-size: larger
}
.font-size-xlarge{
  font-size: x-large;
}

.navbar-brand {
  font-size: 150%;
}

.nav-link {
  font-size: 150%;
}


.links, .links:hover {
  color: var(--light-blue);
  text-decoration: none;
}

.brand-icons span {
  margin: 10px;
}

.fa-brands {
  font-size: large;
}

/* .contact-us-brand-icons .fa-brands{
  font-size: xx-large;
} */

#contact-us-brand-icons .fa-brands {
  font-size: xx-large;
}

.container {
  margin-left: 0;
  margin-right: 0;
}

.disclaimer {
  font-size: 95%;
}

.width-100vw {
  width: 100vw;
}

/* .cover-box{
  position: absolute;
  width: 100%;
  height: 1.3rem;
} */

.iFrame-col {
  padding: 0%;
  overflow-x: auto;
}


.margin-left-5vmin {
  margin-left: 5vmin;
}

p {
  margin-bottom: 0%;
}

#Contact-Us {
  color: rgb(150, 255, 194);
}

#NSE {
  font-size: 50%;
  margin-top: 1.5%;
}

/* header {
  background: linear-gradient(var(--black-red-gradient));
} */



.dividend-table,
.amortisation_table {
  /* margin-left: 0; */
  position: inherit;
  background-color: var(--pastel-green);
  padding: 0% 5% 0% 5%;
  border-radius: 0.2in;
  border: 2px;
  overflow-y: auto;
  /* width: 90%; */
  max-height: 70vmax;
  line-height: 2.5;
  /* box-shadow: 10px 5px 5px black; */
}

.table-cell {
  padding-top: 10px;
}

.dividend-table thead {
  position: sticky;
  top: 0;
  background-color: var(--pastel-green);
}

.dividend-table tbody,
.amortisation_table tbody {
  /* width: 100%; */
  max-height: 500vmax;
  /* overflow-y: scroll; */
}

.dividend-table tbody tr td,
.amortisation_table tbody tr td {
  color: black;
}

.dividend-table tr th,
.amortisation_table tr th {
  white-space: nowrap;
  color: black;
}

.row {
  /* margin-left: 0; */
  padding-left: 0;
  margin: 0 auto;
}

.row > *{
  padding: 0;
}


::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background: rgb(25, 25, 25);
}

::-webkit-scrollbar-thumb {
  background: rgb(71, 71, 71);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(96, 96, 96);
}


.sort_icon {
  cursor: pointer;
}

.height-fixed {
  display: flex;
  height: 35vmin;
  align-items: center;
}

.table_head {
  position: sticky;
}

.btn-nav,
.btn-submit {
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  text-shadow: 0px 0px 0px #666666;
  font-family: Arial;
  color: #ffffff;
  font-size: 16px;
  background: var(--dark-bg);
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-property: transform;
  width: fit-content;
}

.btn-nav:hover,
.btn-submit:hover {
  color: #FFFFFF !important;
  background-color: black;
  transform: scale(0.9);
  transform-origin: bottom;
  /* border: black; */
}


.top-right-spacingbox {
  width: 12%;
}

.spacing-box-2 {
  width: 82vw;
}

/* CONTACT FORM */

.form {
  /* box-sizing: border-box; */
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-form {
  padding-bottom: 30px;
  margin-top: 12px;
  margin: auto;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.bg-layered-peaks {
  background-image: url(../src/assets/layered-peaks-haikei.svg);
  background-size: cover;
}

.contact-form>input,
.contact-form>textarea {
  padding: 12px;
  margin: 35px auto;
  border: 3px solid var(--pastel-green);
  color: white;
  background-color: var(--past);
  border-radius: 4px;
  display: block;
  width: 35vmax;
}


.contact-form>textarea {
  height: 100px;
  resize: none;
}

.contact-form>input[type="submit"] {
  max-width: 10vmax;
  color: black;
  padding-left: 1%;
  padding-right: 1%;
  cursor: pointer;
  border: 0;
  transition-duration: 0.35s;
  transition-property: transform;
  margin-bottom: 0;
  margin: auto;
  background-color: var(--pastel-green);
}

.contact-form>input[type="submit"]:hover {
  transform: scale(0.9);
  background-color: var(--pastel-green);
}

/* CONTACT FORM */
/* ========================== */


#footer {
  background-color: var(--dark-bg);
  font-size: x-small;
}

.footer {
  bottom: 0;
}

/* ========================== */

/* FILTERS */

.form,
.dates-form {
  box-sizing: border-box;
  font-family: Helvetica;
  /* height: 70vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

fieldset {
  margin: auto;
  /* width: 20vmin; */
  text-align: center;
  border-radius: 2px;
}

fieldset>select,
input {
  border: none;
  margin: auto;
  outline: none;
  scroll-behavior: smooth;
}


fieldset>select:active {
  border-style: none;
}

fieldset>legend {
  margin-right: auto;
  margin-left: 10px;
  padding: 0 5px 0 5px;
  font-size: smaller;
}


.form-control,
.form-control-sm {
  border: none;
}


/* .form-control:hover,
.form-control-sm:active {
  filter: drop-shadow(0.5);
  color: #FFFFFF;
  box-shadow: #FFFFFF 2in;
} */

.list-group-item {
  background-color: black;
}

#timeRange {
  cursor: pointer;
  background: transparent;
  color: var(--baby-powder);
  border: 0;
  outline: none;
  border-radius: 0;
}

#timeRange option {
  background: black;
}

#startDate,
#endDate {
  width: max-content;
  background-color: transparent;
  color: white;
  border: transparent;
}

#startDate::-webkit-calendar-picker-indicator,
#endDate::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.filter-section-tabs {
  width: 80%;
  box-shadow: 5px 10px 20px black,
    -10px -10px 30px black;
  position: relative;
  z-index: 200;
}

.nav-tabs {
  border: none;
}


.filter-section-tabs .nav-link.active {
  background-color: var(--light-blue);
  color: black;
}

.filter-section-tabs .nav-link {
  border: none;
  color: var(--light-blue);
  background-color: #000c18;
  height: 100%;
}

.filter-section-tabs .nav-link.active:hover {
  color: black;
}

.filter-section-tabs .nav-link:hover {
  border: none;
  color: white;
}


.filters {
  background-color: var(--light-blue);
  color: black;
  border-radius: 0.2in;
  /* display: inline-block; */
}

.filters fieldset {
  flex: 2 1 auto;
  margin: 4px;
}

.border-filter {
  /* border-color: var() !important; */
  border: white 2px solid;
}

.filters fieldset input {
  margin: 0 15px 15px 15px;
  width: 150px;
  /* background-color: white; */
}

.filters fieldset select:focus-visible {
  outline: none;
}


.filters fieldset select {
  background-color: transparent;
}

.filters fieldset select option {
  border: none;
}

.custom-select {
  background-color: white;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  border-radius: .25rem;
}


/* .filters fieldset legend{
  margin: 2px;
} */

.filter-flex {
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  row-gap: 10px;
  column-gap: 2em;
}

/* ========================= */

/* ======================= */

/* .slide-bck-center {
  box-shadow: 12px 10px 48px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: 12px 10px 48px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 12px 10px 48px -2px rgba(0,0,0,0.75);
} */

.child-center {
  display: grid;
  place-items: center;
}


.card-div-cal {
  height: fit-content;
  border: solid 2px black;
}

/* BREADCRUMB */

.breadcrumb {
  margin-left: 5vw;
}

.breadcrumbs-link {
  color: white;

}

.breadcrumbs-link:hover {
  text-decoration: none;
  color: var(--pastel-green);
}

/* ====================== */

.hist-data-filters {
  margin: 0 10px 0 10px;
}


/* HOME */

.home {
  /* background-color: var(--bs-black); */
  height: 100vh;
}

.shadowed-box {
  height: 200px;
  width: 200px;
  border-radius: 29px;
  background: #1c1c1c;
  box-shadow: -18px 18px 36px #151515,
    18px -18px 36px #232323;
}

.bg-grey {
  background-color: #1c1c1c;
}

/* .blob {
  width: 50vmin;
  height: 50vmin;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3ClinearGradient id=%22linearGradientId%22 gradientTransform=%22rotate(-45 0.5 0.5)%22%3E %3Cstop offset=%220%25%22 stop-color=%22%230b0049%22 %2F%3E %3Cstop offset=%2250%25%22 stop-color=%22%23870db5%22 %2F%3E %3Cstop offset=%22100%25%22 stop-color=%22%239a0000%22 %2F%3E %3C%2FlinearGradient%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M711%2C651Q598%2C802%2C420.5%2C744.5Q243%2C687%2C242%2C499.5Q241%2C312%2C442%2C185.5Q643%2C59%2C733.5%2C279.5Q824%2C500%2C711%2C651Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22url(%23linearGradientId)%22 d=%22M711%2C651Q598%2C802%2C420.5%2C744.5Q243%2C687%2C242%2C499.5Q241%2C312%2C442%2C185.5Q643%2C59%2C733.5%2C279.5Q824%2C500%2C711%2C651Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
} */

.link-page , .legal{
  color: var(--light-blue);
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 0px;
}

#how-info{
  font-size: x-small;
  color: var(--pastel-green);
}

.link-page:hover ,.legal:hover {
  color: var(--pastel-green);
}

.bg-transparent {
  background: transparent;
  border: none;
}

.fab {
  padding: 5px;
}

.color-white {
  color: white;
}

.btn-transparent {
  background-color: transparent;
  border: solid 1px white;
  transform: scale(0.9);
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.blog-card {
  width: 40vmin;
}

.highlighted-text {
  text-decoration: underline;
}

.newtons-cradle {
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: var(--baby-powder);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: '';
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}


/* Referall Link section */
/* 
.get-started-card {
  width: 190px;
  height: 254px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  background: url(../src/assets/bbblurry.svg);
  background-size: cover;
  /* box-shadow: 15px 15px 30px rgb(25, 25, 25),
              -15px -15px 30px rgb(60, 60, 60); */



.bg-black {
  background-color: black;
}

.text-black {
  color: black;
}

.screen-center {
  position: absolute;
  /* or absolute */
  top: 50%;
  left: 50%;
}

.w-150 {
  width: 150%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.comment-box button{
  background-color: black;
}

#otpPriceInfoModal table tbody tr td:first-child {
  padding-right: 20px;
}

#otpPriceInfoModal hr{
  width: 128%;
}

#affliate-link{
  width: -webkit-fill-available;
  border-radius: 0.05in;
}

#total-row td:nth-child(n+3){
  font-weight: bold;
}

.font-weight-bold{
  font-weight: bold;
}

.loader-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}